<template>
	<div class="main">
		<div class="email">
			<div class="content">
				<h5>登录邮箱</h5>
				<el-form ref="form" :model="form" label-width="5rem">
					<el-form-item label="账号">
						<el-input v-model="form.account"></el-input>
					</el-form-item>
					<el-form-item label="@">
						<el-select v-model="form.email" placeholder="请选择" style="width: 100%;">
							<el-option v-for="item in emailoptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="密码">
						<el-input placeholder="请输入密码" v-model="form.password" show-password></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">登录</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				emailoptions: [{
					value: '1',
					label: 'whuifafoods.com'
				}, {
					value: '2',
					label: 'zezhongfoods.com'
				}, {
					value: '3',
					label: 'gdmiracle.com'
				}, {
					value: '4',
					label: 'sdhuifa.com'
				}],
				form: {
					account: '',
					email: '1',
					password: ''
				}
			}
		},
		methods: {
			onSubmit() {
				console.log('submit!');
			}
		}
	}
</script>

<style lang="less">
	.email {
		background-color: #f2f2f2;
		min-height: 43.3125rem;
		padding: 12.5rem 0 8.9375rem;

		.content {
			width: 25.5rem;
			height: 25.625rem;
			background: #FFFFFF;
			border-radius: 0.5rem;
			margin: auto;
			padding: 2.3125rem 0;
			padding-right: 2.3125rem;

			h5 {
				text-align: center;
				margin-bottom: 2.125rem;
			}
		}
		.el-input__inner {
		    border-radius: 1.375rem;
			height: 2.8125rem;
		}
	}
</style>